import React from 'react';
import Layout from '../../components/layout/Layout';
import dossier from '../../data/pratiche-patenti/duplicato-deterioramento/dossier.json';
import PageNavigator from '../../components/layout/PageNavigator';
import ContentDossierPrice from '../../components/ContentDossierPrice';
import Seo from '../../components/layout/SEO';

const DuplicatoDeterioramento = () => {
  return (
    <Layout>
      <Seo
        title={"Duplicato per Deterioramento | Autoscuola Faro, Pisa"}
        description={"Richiedi il duplicato della tua patente di guida che si è deteriorata o è diventata illeggibile. Bastano fototessere e carta d'identità."}
        keywords={[
          "Duplicato Patente di Guida per Deterioramento Pisa",
          "Patente Deteriorata",
          "Modulo TT2112",
          "Patente Deteriorata",
          "Duplicato per Patente Rotta Pisa"
        ]}
      />

      <PageNavigator link="/pratiche-patenti" text="Pratiche patenti" />
      <ContentDossierPrice dossier={dossier} />
    </Layout>
  );
};

export default DuplicatoDeterioramento;
